import React from 'react'
import Modal from '../Modal/Modal'
import { FormattedMessage } from 'react-intl'
import NamedLink from '../NamedLink/NamedLink'
import Button from '../Button/Button'
import css from './OwnerModal.module.css'
import { H5 } from '../Heading/Heading'

const OwnerModal = ({ setIsModalOpen, isModalOpen }) => {
  return (
    <Modal
      id="renterProfilePage"
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      onManageDisableScrolling={() => { }}
      className={css.modalContainer}
    >
      <div>
        <H5 className={css.title}><FormattedMessage id="LandingPage.newListing" /></H5>
        <p className={css.description}><FormattedMessage id="LandingPage.wantToBecomeOwner" /></p>
      </div>
      <div className={css.twoButtons}>
        <NamedLink
          name="ProfileRolePage"
          className={css.button}
        >
          <Button type="button">
            <FormattedMessage id="FieldBoolean.yes" />
          </Button>
        </NamedLink>
        <Button type="button" className={css.button} onClick={() => {
          setIsModalOpen(false)
        }}>
          <FormattedMessage id="FieldBoolean.no" />

        </Button>
      </div>
    </Modal>
  )
}

export default OwnerModal